
import { copySelectElementContents } from '@mobro/libs';
import { Message } from 'element-ui';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ArticleCreate from './create.vue';

@Component({})
export default class ToolTips extends Vue {
    @Prop() newsCount!: number;

    async onCopyTemplate() {
        const el = document.querySelector('#template') as HTMLElement;
        if (!el) return;
        copySelectElementContents(el);
        Message.success('模板已复制到粘贴板~~');

        (this.$parent as ArticleCreate).formData.articleText = el.innerHTML;
    }
}
