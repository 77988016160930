
import { OSS_HOST } from '@/store/constants';
import { getTodayNewsName } from '@/libs/utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ImgJudge extends Vue {
    @Prop() type!: 'p' | 'm';
    @Prop({ default: 'news/share_pics' }) dir!: string;

    private imgSrc = '';
    private ext = ['jpeg', 'png', 'webp', 'jpg', 'gif'];

    created() {
        this.joinImgSrc();
    }

    joinImgSrc() {
        if (this.ext[0]) {
            this.imgSrc = `${OSS_HOST}/${this.dir}/${getTodayNewsName()}${this.type}.${this.ext[0]}`;
            this.ext = this.ext.splice(1);
        } else {
            this.$emit('success', '', this.type);
        }
    }

    onError() {
        this.joinImgSrc();
    }

    onLoad() {
        this.$emit('success', this.imgSrc, this.type);
    }
}
