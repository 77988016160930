var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.visible)?_c('div',{staticClass:"create-loading"},[_c('el-progress',{attrs:{"type":"dashboard","percentage":_vm.percentage,"color":[
            { color: '#f56c6c', percentage: 0 },
            { color: '#e6a23c', percentage: 40 },
            { color: '#5cb87a', percentage: 60 },
            { color: '#1989fa', percentage: 80 },
            { color: '#6f7ad3', percentage: 100 },
        ]}}),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.createLoadingText))])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }