/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Ifunction, Iobject } from '@mobro/libs/es/type';

export interface IFile {
    url: Blob;
    dataURL: string;
    orientation: number;
    file: File | Iobject;
    size: number;
    suffix: string;
}

interface IConfig {
    maxSize?: number;
}

interface IImageConfig extends IConfig {
    fileType?: string;
}

// 设置初始值
const getInitData = () => ({
    dataURL: '',
    fileType: 'image/jpeg',
    file: {},
    maxSize: 500, // kb
});

export const exif = (file: File, config: IConfig = {}): Promise<IFile> => {
    const fileType = file.type;
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
            const dataURL = (e.target as Iobject).result as string;
            zip(
                {
                    dataURL,
                    fileType,
                    ...config,
                },
                resolve,
                reject,
            );
        };
        reader.readAsDataURL(file);
    });
};

export function exifBase64(dataURL: string, config: IImageConfig = {}): Promise<IFile> {
    return new Promise(function (resolve, reject) {
        zip(
            {
                dataURL,
                ...config,
            },
            resolve,
            reject,
        );
    });
}

function setZipRadio(size: number): number {
    return size > 200000 ? 0.9 : size > 150000 ? 0.8 : 0.7;
}

export function zip(data: Iobject, resolve: Ifunction, reject: Ifunction) {
    let { dataURL, fileType, file, maxSize } = {
        ...getInitData(),
        ...data,
    };

    if (!dataURL) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('Fail to get the image');
        return;
    }
    const img = new Image();
    const canvas = document.createElement('canvas');
    const size = dataURL.length - (dataURL.length / 8) * 2;
    console.log(`压缩后当前大小：${size / 1024}kb  \n压缩比率：${setZipRadio(size)}`);

    img.addEventListener('load', function reduce() {
        dataURL = img.src;
        const orientation = getOrientation(dataURItoBuffer(dataURL));
        const size = dataURL.length - (dataURL.length / 8) * 2;
        const reduceStep = setZipRadio(size); // 每次尺寸减少为上次尺寸的百分之几

        function clip(conf: Iobject) {
            // 目标图片尺寸
            let { targetWidth, targetHeight, dataURL } = conf;
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

            if (ctx) {
                if (orientation > 0) {
                    orientationHelper(canvas, ctx, orientation);
                }

                ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
                dataURL = canvas.toDataURL(fileType, 0.9);
                if (size / 1024 > maxSize) {
                    img.src = dataURL;
                } else {
                    console.log(`压缩后的文件大小：${size / 1024}kb`);

                    resolve({
                        url: dataURItoBlob(dataURL),
                        dataURL,
                        orientation,
                        file,
                        size,
                        suffix: fileType.split('/')[1] || 'jpeg',
                    });
                }
            } else {
                reject({
                    error: '出错了',
                });
            }
        }

        clip({
            targetWidth: img.width * reduceStep,
            targetHeight: img.height * reduceStep,
            dataURL: img.src,
        });
    });

    img.src = dataURL;
}

/* eslint-disable no-bitwise */
/* eslint-disable default-case */
/**
 * 检查图片是否有被压扁，如果有，返回比率
 * ref to http://stackoverflow.com/questions/11929099/html5-canvas-drawimage-ratio-bug-ios
 */
export const detectVerticalSquash = (img: HTMLImageElement) => {
    // 拍照在IOS7或以下的机型会出现照片被压扁的bug
    let data;
    const ih = img.naturalHeight;
    const canvas = document.createElement('canvas');
    canvas.width = 1;
    canvas.height = ih;
    canvas.style.opacity = '0';
    const ctx = canvas.getContext('2d');
    if (ctx) {
        ctx.drawImage(img, 0, 0);
        try {
            // eslint-disable-next-line prefer-destructuring
            data = ctx.getImageData(0, 0, 1, ih).data;
        } catch (err) {
            console.log('Cannot check verticalSquash: CORS?');
            return 1;
        }
        let sy = 0;
        let ey = ih;
        let py = ih;
        while (py > sy) {
            const alpha = data[(py - 1) * 4 + 3];
            if (alpha === 0) {
                ey = py;
            } else {
                sy = py;
            }
            py = (ey + sy) >> 1; // py = parseInt((ey + sy) / 2)
        }
        const ratio = py / ih;
        return ratio === 0 ? 1 : ratio;
    }
    return 1;
};

/**
 * dataURI to blob, ref to https://gist.github.com/fupslot/5015897
 * @param dataURI
 */
export const dataURItoBuffer = (dataURI: string): ArrayBuffer => {
    const byteString = atob(dataURI.split(',')[1]);
    const buffer = new ArrayBuffer(byteString.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < byteString.length; i++) {
        view[i] = byteString.charCodeAt(i);
    }
    return buffer;
};

export const dataURItoBlob = (dataURI: string) => {
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const buffer = dataURItoBuffer(dataURI);
    return new Blob([buffer], {
        type: mimeString,
    });
};

/**
 * 获取图片的orientation
 * ref to http://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side
 */
export const getOrientation = (buffer: ArrayBuffer) => {
    const view = new DataView(buffer);
    // eslint-disable-next-line eqeqeq
    if (view.getUint16(0, false) != 0xffd8) {
        return -2;
    }
    const length = view.byteLength;
    let offset = 2;
    while (offset < length) {
        const marker = view.getUint16(offset, false);
        offset += 2;
        // eslint-disable-next-line eqeqeq
        if (marker == 0xffe1) {
            // eslint-disable-next-line no-cond-assign
            if (view.getUint32((offset += 2), false) !== 0x45786966) {
                return -1;
            }
            // eslint-disable-next-line eqeqeq
            const little = view.getUint16((offset += 6), false) == 0x4949;
            offset += view.getUint32(offset + 4, little);
            const tags = view.getUint16(offset, little);
            offset += 2;
            for (let i = 0; i < tags; i++) {
                // eslint-disable-next-line eqeqeq
                if (view.getUint16(offset + i * 12, little) == 0x0112) {
                    return view.getUint16(offset + i * 12 + 8, little);
                }
            }
            // eslint-disable-next-line eqeqeq
        } else if ((marker & 0xff00) != 0xff00) {
            break;
        } else {
            offset += view.getUint16(offset, false);
        }
    }
    return -1;
};

/**
 * 修正拍照时图片的方向
 * ref to http://stackoverflow.com/questions/19463126/how-to-draw-photo-with-correct-orientation-in-canvas-after-capture-photo-by-usin
 */
export const orientationHelper = (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, orientation: number) => {
    const w = canvas.width;
    const h = canvas.height;
    if (orientation > 4) {
        canvas.width = h;
        canvas.height = w;
    }
    switch (orientation) {
        case 2:
            ctx.translate(w, 0);
            ctx.scale(-1, 1);
            break;
        case 3:
            ctx.translate(w, h);
            ctx.rotate(Math.PI);
            break;
        case 4:
            ctx.translate(0, h);
            ctx.scale(1, -1);
            break;
        case 5:
            ctx.rotate(0.5 * Math.PI);
            ctx.scale(1, -1);
            break;
        case 6:
            ctx.rotate(0.5 * Math.PI);
            ctx.translate(0, -h);
            break;
        case 7:
            ctx.rotate(0.5 * Math.PI);
            ctx.translate(w, -h);
            ctx.scale(-1, 1);
            break;
        case 8:
            ctx.rotate(-0.5 * Math.PI);
            ctx.translate(-w, 0);
            break;
    }
};
