
import { Component, Vue } from 'vue-property-decorator';
import { IArticle, IParagraph, ISentenceItem } from './type';
import { Ifunction, Iobject } from '@mobro/libs/es/type';
import { href2query, MoDate, setTimeoutSync } from '@mobro/libs';
import html2canvas from 'html2canvas';
import { Message } from 'element-ui';
import { exifBase64 } from '@/libs/exif';
import ArticlePreview from './Preview/ArticlePreview.vue';
import ToolTips from './ToolTips.vue';
import CreateLoading from './CreateLoading.vue';
import { ElForm } from 'element-ui/types/form';
import ImgJudge from './ImgJudge.vue';
import { IRequestMethod, request } from '@mobro/request';
import { date2name, getOssUrl, getRandomOriginNews, getRemoveOssPrefixPath, getTodayNewsDate } from '@/libs/utils';
import AutoCreate from './AutoCreate/index.vue';

@Component({
    components: {
        ArticlePreview,
        ToolTips,
        CreateLoading,
        ImgJudge,
        AutoCreate,
    },
})
export default class ArticleCreate extends Vue {
    public formData = {
        articleName: getTodayNewsDate(),
        author: 'mobro',
        sentence: '',
        lunar: '',
        articleText: `## 关注我们，每日快速阅览热门事件`,
        showQR: true, // 是否显示二维码
        picture: '', // 美图分享
        morning: '', // 早安日签
    };
    private articleDesp = {};
    private posterUrl = '';
    private posterWhiteUrl = '';
    private showPreview = false;
    private newsItemList: ISentenceItem[] = [];
    private fullScreen = false;
    private newsCount = 0;
    private showCreateLoading = false;
    private createLoadingText = '正在制作中请稍后~~';
    private createLoadingPercent = 0;
    private sentenceSeriesList: string[] = []; // 句子分类
    public showAutoCreate = false;
    private activityId = href2query()?.activityId;

    onAutoCreateSuccess(str: string, showAutoCreate: boolean) {
        this.showAutoCreate = showAutoCreate;
        this.formData.articleText = str;
    }

    onBeforeCreateArticle(formName: string) {
        (this.$refs[formName] as ElForm)?.validate(valid => {
            if (valid) {
                this.onCreateArticle();
            } else {
                console.warn('请检查你的参数列表');

                return false;
            }
        });
    }

    onCreateArticle() {
        if (!this.activityId) {
            Message.error('缺失activityId');
            return;
        }

        const article: IArticle = {};
        const paragraph = this.formData.articleText.split(/\s# /).filter((s: string) => s);

        paragraph.forEach((par: string) => {
            if (par.includes('HH/ ')) {
                const parArr: ISentenceItem[] = par.split('HH/ ').map((s: string) => {
                    return {
                        title: s.trim(),
                    };
                });

                const calendar: IParagraph = {
                    title: (parArr.shift()?.title || '').replace('# ', ''),
                    desp: parArr,
                };
                article.calendar = calendar;
            } else if (par.includes('ZZ/ ')) {
                const parArr: ISentenceItem[] = par
                    .split('ZZ/ ')
                    .filter((s: string) => {
                        // 精品句子分类
                        const seriesList: string[] = s.split('@@');
                        if (seriesList.length > 1) {
                            this.sentenceSeriesList = seriesList
                                .map((ser: string) => ser.trim())
                                .filter((ser: string) => ser);
                        }

                        return seriesList.length <= 1;
                    })
                    .map((s: string) => {
                        return {
                            title: s.trim(),
                        };
                    });
                const sentence: IParagraph = {
                    title: parArr.shift()?.title || '',
                    desp: parArr,
                };
                article.sentence = sentence;
            } else if (par.includes('## ')) {
                const news: IParagraph = {
                    title: '',
                    desp: [],
                };

                const parArr: ISentenceItem[] = par
                    .split('## ')
                    .filter((s, i) => {
                        i <= 0 && (news.title = s.trim());
                        return i > 0;
                    })
                    .sort(() => (Math.random() > 0.5 ? 1 : -1))
                    .map((s: string, index: number) => {
                        const newsItem: Iobject = { title: '', img: [], description: [] };
                        s.trim()
                            .split('DD/ ')
                            .forEach((de: string, idx: number) => {
                                de = de.trim();

                                if (idx === 0) {
                                    de = /（.+）$/g.test(de) ? de : `${de}（${getRandomOriginNews()}）`;
                                    newsItem.title = (index === 8 ? `${de}（今个儿简讯）` : de)
                                        .replace(/“/g, '「')
                                        .replace(/”/g, '」')
                                        .replace(/,/g, '，')
                                        .replace(/:/g, '；');
                                } else {
                                    /**
                                     * 匹配图片地址 或者 用图片名称[eg: 2022010601.jpeg]来拼接图片地址
                                     */
                                    const [, imgUrl, imgName] =
                                        /^!\[\]\((?:(https?:\/\/.+)|(\d{10}\.(?:jpe?g|png|gif|webp)))\)$/.exec(de) ?? [
                                            '',
                                            '',
                                            '',
                                        ];

                                    if (imgUrl) {
                                        newsItem.img.push(imgUrl);
                                    } else if (imgName) {
                                        newsItem.img.push(getOssUrl(`/news/article_pics/${imgName}`));
                                    } else {
                                        newsItem.description.push(de);
                                    }
                                }
                            });

                        return newsItem as ISentenceItem;
                    });

                news.desp = parArr;

                article.news = news;
            }
        });

        const nowTemplate = MoDate.format('YYYY-MM-DD', Date.now());
        if (nowTemplate === this.formData.articleName) {
            this.$confirm(
                `当前日期为: ${nowTemplate}，文章时间为${this.formData.articleName}`,
                '确定是要今天要发布的文章吗？',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                },
            ).then(() => {
                this.createPoster(article);
            });
        } else {
            /** 此处是字符串比较哦~ */
            if (nowTemplate < this.formData?.articleName) {
                this.createPoster(article);
            } else {
                Message.error('请确认你的发布时间');
            }
        }
    }

    beforeCreatePoster(articleDesp: IArticle): boolean {
        console.log('文章详情: ', articleDesp);

        this.newsItemList = articleDesp?.news?.desp ?? [];
        if (!this.formData.lunar) {
            const lunar = ((articleDesp?.calendar?.desp ?? [])[0]?.title ?? '').split(' ');
            this.formData.lunar = lunar.length > 2 ? `${lunar[1].substr(0, 2)}${lunar[2]}` : '';
        }
        if (!this.formData.sentence) {
            this.formData.sentence = (articleDesp?.sentence?.desp ?? []).map((s: ISentenceItem) => s.title).join('');
        }

        if (!this.formData.lunar && this.newsItemList.length > 1) {
            Message.error('农历不可为空！！！');
            return false;
        } else if (!this.formData.sentence && this.newsItemList.length > 1) {
            Message.error('精句不可为空！！！');
            return false;
        }

        articleDesp.title = this.dateStr;
        articleDesp.author = this.formData.author;
        return true;
    }

    async createPosterItem(poster: HTMLElement, conf: Iobject = {}) {
        const config = Object.assign(
            {},
            {
                name: 'red',
                type: '红底海报',
                poster,
                maxSize: 500,
                fileName: `${date2name(this.formData.articleName)}`,
            },
            conf,
        );
        const prefixLogType = (str: string): string => `【${config.type}】 ${str}`;
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve: Ifunction, reject: Ifunction) => {
            try {
                // 绘制海报，压缩海报
                const canvas = await html2canvas(poster);
                const posterDataURL = canvas.toDataURL('image/png');
                const posterFormData = new FormData();

                const fileTransformData = await exifBase64(posterDataURL, { maxSize: config.maxSize }).then(res => {
                    posterFormData.append('file', res.url);
                    posterFormData.append('fileName', config.fileName);
                    posterFormData.append('dir', 'news/poster');

                    return res;
                });
                console.log(prefixLogType('海报详情: '), posterFormData.get('file'));

                if (config.name === 'red') {
                    this.posterUrl = fileTransformData.dataURL;
                } else if (config.name === 'white') {
                    this.posterWhiteUrl = fileTransformData.dataURL;
                }

                const newsServicePoster = await request(
                    {
                        url: '/api/common/file/upload',
                        method: IRequestMethod.POST,
                        params: posterFormData,
                    },
                    {
                        timeout: 30000,
                    },
                );
                resolve(newsServicePoster);
            } catch (e) {
                reject('exception');
            }
        });
    }

    async createPoster(articleDesp: IArticle) {
        if (!this.beforeCreatePoster(articleDesp)) return;

        // if (articleDesp.title) return;
        const poster = document.querySelector('#poster') as HTMLElement;
        const posterWhite = document.querySelector('#posterWhite') as HTMLElement;
        if (poster) {
            try {
                await setTimeoutSync(200);

                this.createLoadingText = '海报制作中...';
                this.createLoadingPercent = 10;
                this.showCreateLoading = true;
                const newsServicePoster = await Promise.all<Iobject>([
                    this.createPosterItem(poster, {
                        type: '红底海报',
                        name: 'red',
                        maxSize: 700,
                        fileName: `${date2name(this.formData.articleName)}`,
                    }),
                    this.createPosterItem(posterWhite, {
                        type: '白底海报',
                        name: 'white',
                        maxSize: 700,
                        fileName: `${date2name(this.formData.articleName)}-white`,
                    }),
                ]).then(([res, resWhite]) => {
                    return { ...res, whiteUrl: resWhite.path, url: res.path };
                });

                // 上传海报
                this.createLoadingText = '海报上传中..';
                this.createLoadingPercent = 50;

                if (newsServicePoster.url && newsServicePoster.whiteUrl) {
                    // 解析文章
                    this.createLoadingText = '解析文章中...';
                    this.createLoadingPercent = 70;

                    const res = await request({
                        url: '/api/article/create',
                        method: IRequestMethod.POST,
                        params: {
                            newsName: date2name(this.formData.articleName),
                            newsDesp: articleDesp,
                            poster: getRemoveOssPrefixPath(newsServicePoster.url),
                            posterWhite: getRemoveOssPrefixPath(newsServicePoster.whiteUrl),
                            picture: getRemoveOssPrefixPath(this.formData?.picture),
                            morning: getRemoveOssPrefixPath(this.formData?.morning),
                            activityId: this.activityId,
                        },
                    });

                    this.createLoadingText = '解析精品句子中...';
                    this.createLoadingPercent = 90;
                    const resSentence = await request({
                        url: '/api/article/sentence/create',
                        method: IRequestMethod.POST,
                        params: {
                            sentence: this.formData.sentence,
                            series: this.sentenceSeriesList.join(','),
                            used: 1,
                        },
                    });

                    if (res && resSentence) {
                        await setTimeoutSync(500);
                        this.createLoadingText = '创建文章完成';
                        this.createLoadingPercent = 100;
                        throw 'success';
                    }
                } else {
                    Message.error('海报上传失败，请重试！');
                    throw 'exception';
                }
            } catch (e) {
                console.log(e, 'error');

                e !== 'success' && Message.error('创建文章失败！');
                await setTimeoutSync(300);
                this.showCreateLoading = false;
                this.createLoadingText = '';
                this.createLoadingPercent = 0;
            }
        }
    }

    onImgLoadSuccess(imgSrc: string, type: string) {
        if (type === 'p') {
            this.formData.picture = imgSrc;
        } else if (type === 'm') {
            this.formData.morning = imgSrc;
        }
    }

    onTextAreaChange(val: string) {
        this.newsCount = val.split('## ').length - 1;
    }

    get dateArr(): string[] {
        const o = MoDate.getTimeQuota(new Date(this.formData.articleName));

        return [
            MoDate.format2('D', o),
            MoDate.format2('YYYY年M月', o),
            `${this.formData.lunar ? `农历${this.formData.lunar}` : ''} ${MoDate.format2('周W', o)}`,
        ];
    }

    get dateStr() {
        return `${this.dateArr[1]}${this.dateArr[0]}日 ${this.dateArr[2]}`;
    }

    private formRule = {
        articleName: [
            {
                required: true,
                message: '发表时间必填',
                trigger: 'blur',
            },
            {
                validator: (rule: Iobject, value: string, callback: Ifunction) => {
                    if (!/^20[234]\d-\d{1,2}-\d{1,2}$/.test(value)) {
                        callback(new Error('文章名称格式不正确'));
                    }
                    callback();
                },
            },
        ],
        author: [
            {
                required: true,
                message: '作者必填',
                trigger: 'blur',
            },
        ],
        articleText: [
            {
                required: true,
                message: '文章内容不能少',
                trigger: 'blur',
            },
        ],
        picture: [
            {
                required: true,
                message: '美图分享必填',
                trigger: 'blur',
            },
            {
                validator: (rule: Iobject, value: string, callback: Ifunction) => {
                    if (!/^https?:.+$/.test(value)) {
                        callback(new Error('美图分享地址格式不正确'));
                    }
                    callback();
                },
            },
        ],
        morning: [
            {
                required: true,
                message: '早安日签必填',
                trigger: 'blur',
            },
            {
                validator: (rule: Iobject, value: string, callback: Ifunction) => {
                    if (!/^https?:.+$/.test(value)) {
                        callback(new Error('早安日签格式不正确'));
                    }
                    callback();
                },
            },
        ],
    };
}
